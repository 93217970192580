const ContribVisualiser = require("./../../Core/Simulator/contrib_prevusualize");

$('document').ready(function () {

    var cartItem = $('#commission_cart_item_id').val();

    var cumacAmount =  $('#commission_cumac_amount').val();

    var clientCom =  $(".clientCom");
    var proCom =  $(".proCom");
    var bonus = $('.bonus_amount');

    var baseClientCom = clientCom.text().replace(/\s+/g, '');
    var baseProCom = proCom.text().replace(/\s+/g, '');

    var currentComValue = $('#commission_com').val();

    $('#commission_com').on('input',function(e){
        let tempComValue = ($(this).val() - currentComValue) * cumacAmount;
        clientCom.text(Math.round(parseInt(baseClientCom) + tempComValue));
        proCom.text(Math.round(parseInt(baseProCom) - tempComValue));
    });

    $('#commission_com').on('change',function(e){
      updateCommission();
    });

    function updateCommission() {
        if($('.com_form').length > 0) {
        let form = $('.com_form');
        let data = {};
        form.serializeArray().forEach((object)=>{
            data[object.name] = object.value;
        });
        displayLoadingOverlay($('#commission_com'));
        $.ajax({
            url: Routing.generate('pro_handle_com'),
            type: 'post',
            dataType: "JSON",
            data: data,
            async: true,
            success:function(data){
            }
        }).statusCode({
            400: function (data) {
                },
            200: function (data) {
                $('.' + cartItem + '_prime').text(clientCom.text() + ' €');
                $('.main_prime').text(clientCom.text() + ' €');
                $('.formatted_total').text(Math.round(parseInt(clientCom.text()) + parseInt(bonus.text())) + ' €');
            }
        }).always(function() { hideLoadingOverlay($('#commission_com')); });
        return false;
        }
        else{
            return false;
        }
    }


    function displayLoadingOverlay(element) {
        element.LoadingOverlay("show", {imageColor: "#1b284f"});
    }

    function hideLoadingOverlay(element) {
        element.LoadingOverlay("hide");
    }

    if (window.location.pathname.split('/').pop() == 'resultat') {
        updateCommission();
    }


// cadre contrib prévisualise control
    if ($('#app_user_registration_pay_partner_directly_0').length > 0) {
        if ($('#app_user_registration_pay_partner_directly_0').is(':checked')) {
            showContribOptin();
        } else {
            hideContribOptin();
        }
    }

    $('#app_user_registration_pay_partner_directly_0').click(function() {
        showContribOptin();
    });
    $('#app_user_registration_pay_partner_directly_1').click(function() {
        hideContribOptin();
    });

    $('form[name=app_user_registration]').submit(function(e) {
        if ( $('.pro-contrib-optin').hasClass('visible') && $('#app_user_registration_contribOptIn').is(':checked') === false) {
            e.preventDefault();
            $('.pro-contrib-optin').find('.field-container').addClass('error');
            $('.pro-contrib-optin .message-error').html("Cocher le champ ' Je certifie avoir pris connaissance.. ' est obligatoire.");
            return false;
        }
    });

    function showContribOptin() {
        $('.pro-contrib-optin')
            .addClass('visible')
            .show();
    }
    function hideContribOptin() {
        $('.pro-contrib-optin')
            .removeClass('visible')
            .hide()
            .find('.field-container').removeClass('error');
        $('.pro-contrib-optin .message-error').html("");
    }

    $('.pro-visualise-cdp-contrib-pdf').on('click', function () {
        let $form = $('form[name=app_user_registration]');
        let itemId = $(this).data('item-id');
        ContribVisualiser.visualiseCdpContribPdf($form, 'pro_visualise_cdp_contrib_pdf', itemId);
    });
    // end of contrib

});



