$('document').ready(function () {
    var carousel = $('.carousel-abc');

    carousel
        .on('jcarousel:reload jcarousel:create', function () {
            var carousel = $(this),
                width = carousel.innerWidth();

            if (width >= 768) {
                width = width / 3;
            }

            carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
        })
        .jcarousel({
            wrap: 'circular',
            items: '.abc'
        });

    if(window.innerWidth < 992) {

        carousel.jcarouselAutoscroll({
            interval: 10000,
            target: '+=1',
            autostart: true
        });

    }

    $('.carousel-abc-pagination .dots')
        .on('jcarouselpagination:active', '.dot', function() {
            $(this).addClass('current');
        })
        .on('jcarouselpagination:inactive', '.dot', function() {
            $(this).removeClass('current');
        })
        .on('click', function(e) {
            e.preventDefault();
        })
        .jcarouselPagination({
            'carousel': $('.carousel-abc-pagination'),
            item: function(page) {
                return '<li class="dot"><a href="#"></a></li>';
            }
        });

    $('.carousel-backgrounds')
        .on('jcarousel:reload jcarousel:create', function () {
            var carousel = $(this),
                width = carousel.innerWidth();

            carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
        })
        .jcarousel({
            wrap: 'circular'
        });

    $('.carousel-testimonials')
        .on('jcarousel:reload jcarousel:create', function () {
            var carousel = $(this),
                width = carousel.innerWidth();

            carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
        })
        .jcarousel({
            wrap: 'circular'
        });




    $('.carousel-testimonials .previous').on('click', function(e){
        e.preventDefault();
        $('.carousel-testimonials').jcarousel('scroll', '-=1');
        $('.carousel-backgrounds').jcarousel('scroll', '-=1');
    });


    $('.carousel-testimonials .next').on('click', function(e){
        e.preventDefault();
        $('.carousel-testimonials').jcarousel('scroll', '+=1');
        $('.carousel-backgrounds').jcarousel('scroll', '+=1');
    });
});
