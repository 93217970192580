import MicroModal from "micromodal";

$('document').ready(function () {
    if($('#partner-validation-modal').length > 0) {
        var popupTriggers = $('.validation-popup-trigger');
        var partnerInput = $('input[name*=partner]');
        var nameSpan = $('#partner-name');
        var partnerId;
        popupTriggers.click(function () {
            partnerId = $(this).data('partner-id');
            partnerInput.val(partnerId);
            nameSpan.html($(this).data('company-name'));
            MicroModal.show("partner-validation-modal");
        });
    }
});