
//Core
import 'Core/main';

//Pro SCSS
import 'scss/Pro/app.scss';

//Pro JS
import "Pro/Partner/partner"
import "Pro/Index/carousel"
import "Pro/Synthese/synthese"
import "Pro/Simulation/com"

//Vte Tools
import "vteTools/cancel"
import "vteTools/precarity-select"
import "vteTools/reportingtable"
import "vteTools/Simulation/global_simulator"
import "vteTools/Simulation/address-conditional-fields"
import "vteTools/Simulation/pro-conditional-fields"
import "vteTools/tooltipsVte"

import "Iframe/Iframe/antargaz"