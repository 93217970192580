
import {showIframeValidatePopUp} from "../../Core/DossierAdministration/popups";

$(document).ready(function() {
    var lineSelector = $('form').data('line_selector');
    if(typeof(lineSelector) === 'undefined')
        lineSelector = '.field-container';

    var newClient = $('input[name*=new_client]');
    var newClientTr = newClient.closest(lineSelector);
    var existingClient = $('input[name*=existing_client]');
    var numClient = $('input[name*=client_number]');
    var numClientTr = numClient.closest(lineSelector);
    var offerType = $('[name*=_offer_type]');
    var fzSuccession = $('[name*=fz_succession]');
    var offerTypeTr = offerType.closest(lineSelector);
    var fzSuccessionTr = fzSuccession.closest(lineSelector);

    var refreshFields = function(evt, resetNewClient) {
        newClientTr.hide();
        numClientTr.hide();
        offerTypeTr.hide();
        fzSuccessionTr.hide();

        var existingSelected = existingClient.filter(':checked');
        if(existingSelected.length == 0) {
            return;
        }

        // Si c'est le bouton 'existing_client' qui vient d'être coché, quelle que soit sa valeur le new_client doit être remis à zéro
        if(evt && existingClient.is(evt.target) && resetNewClient) {
            newClient.filter('[value=0]').prop('checked', true);
        }

        var existingValue = existingSelected.length > 0 ? parseInt(existingSelected.val()) : 0;

        if(existingValue) {
            numClientTr.show();
            offerTypeTr.show();
        } else {
            newClientTr.show();
        }
        fzSuccessionTr.show();

        var newSelected = newClient.filter(':checked');
        var newValue = newSelected.length > 0 ? parseInt(newSelected.val()) : 0;
        if(newValue) {
            offerTypeTr.show();
        }
    };
    newClient.add(existingClient).change(function(evt) {refreshFields(evt);});
    refreshFields();

    $('#iframe_validate_btn').click(function () {
        showIframeValidatePopUp();
    });
});

