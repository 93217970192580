$('document').ready(function () {

    button = $("#children_list_show_button");
    collapser = $("#synthese_collapser");
    listHeight = collapser.height();

    var unwrapped = false;

    button.click(function () {
        if (unwrapped === false) {
            collapser.css('height', 'auto');
            button.text('Réduire la liste');
            unwrapped = true;
            $('html, body').animate({
                scrollTop: button.offset().top - 800
            }, 'slow');
        } else {
            collapser.css('height', listHeight);
            button.text('Voir la liste');
            unwrapped = false;
            $('html, body').animate({
                scrollTop: 0
            }, 'slow');
        }
    });
});