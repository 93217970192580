$('document').ready(function () {

    $(function () {
        // Gestion de l'annulation de dossier
        $('.cancel_href').click(function (e) {
            e.preventDefault();

            if (!confirm("Etes-vous certain de vouloir annuler ce dossier ?")) {
                return;
            }

            var form = $(this).children(":first");
            var form_data = form.serialize();

            var loader = $(this).closest('td').find('.loader').show();

            $.ajax({
                type: 'POST',
                url: Routing.generate('dossier_cancel'),
                data: form_data,
                dataType: 'json',
                success: function (data) {
                    loader.hide()
                }
            }).statusCode({
                200: function (data) {
                    alert(data);
                    window.location.reload();
                },
                503: function (data) {
                    alert(data);
                }

            });
            return false;
        });
    });
});